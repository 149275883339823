import React from "react";

const Index = ()=>{
    return (
        <div className="OurServicePage">
            <div className="About_Us_Title">
                <div className="small_Title">OUR SERVICES</div>
                <h1>OUR SERVICES </h1>
                <small>Shipping plays a vital role in world trade and is the backbone of the world economy,<br/> which makes it indispensable to the world and to meeting the challenge of the Agenda for Sustainable Development</small>
            </div>
        </div>
        
    )
}

export default Index;