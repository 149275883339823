import React from "react";

const Index = ()=>{
    return (
        <div className="request">
            <div className="About_Us_Title">
                <div className="small_Title">REQUEST FOR QUOTE</div>
                <h1>Request For Quote</h1>
            </div>
        </div>
        
    )
}

export default Index;