import React from "react";

const Index = ()=>{
    return (
        <div className="About_US_All_main">
            <div className="About_Us_Title">
                <div className="small_Title">ABOUT US</div>
                <h1>ABOUT US</h1>
            </div>
        </div>
        
    )
}

export default Index;